@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: BeVietnam;
    src: url("/fonts/BeVietnam-Regular.ttf") format("truetype");
}

@font-face {
    font-family: ThunderBold;
    src: url("/fonts/Thunder-BoldLC.otf") format("opentype");
}

@font-face {
    font-family: Thunder;
    src: url("/fonts/Thunder-MediumLC.otf") format("opentype");
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: #FFD10A;
    border-radius: 1px;
}

::-webkit-scrollbar-track {
    background-color: #111111;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #FFD10A;
}

::-webkit-scrollbar-thumb:active {
    background-color: #FFD10A;
}

::-webkit-scrollbar-corner {
    background-color: #111111;
}

::-webkit-scrollbar-track-piece {
    background-color: #111111;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-button:start {
    display: none;
}

::-webkit-scrollbar-button:end {
    display: none;
}



body {
    @apply bg-white font-[BeVietnam];
    @apply overflow-x-hidden    
    
}

html.dark {
    body {
        @apply bg-black font-[BeVietnam];
    }
}

.background-img {
    position: relative;
    @apply bg-[#111111] fixed bottom-0 h-screen w-screen;
}

.background-img {
    > * {
        position: relative;
        z-index: 10;
    }
}

.background-img::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 402px;
    background-image: url("/images/background-logos.svg");
    background-size: cover;
    background-repeat: repeat-x;
    pointer-events: none;
}

a.base-button,
button.base-button {
    @apply flex flex-row items-center justify-center ml-0 mr-4 md:mr-6 last:mr-0 text-white transition-all duration-300 border-2 border-primary
     rounded-tl-[24px] rounded-br-[24px] bg-black
      font-[ThunderBold] font-bold hover:text-primary hover:border-primary hover:bg-black
     focus:bg-black focus:border-primary focus:shadow-none text-[20px] md:text-[40px] w-full p-6;
}

div.base-modal > div {
    @apply bg-white dark:bg-black;
}

div.base-modal > div > div > button {
    @apply bg-white dark:bg-black;
    @apply text-black dark:text-secondary;
}

.full-button {
    @apply py-2 px-8 text-primary uppercase border-primary border-2 border-solid rounded-tl-[8px] rounded-br-[8px]
    font-[BeVietnam] text-[16px] font-light flex items-center leading-[24px] hover:text-black hover:bg-primary;
}

.outline-button {
    @apply text-primary uppercase border-transparent border-b-primary border-2 border-solid p-2;
}

@media screen and (max-width: 550px) {
    .background-img::after {
        height: 90px;
    }
}

a, button {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .logout-button path {
        stroke: #FFD10A;
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
  
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
